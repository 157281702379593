<!--正常授信 上传图片-->
<template>
  <div class="page-upload">
    <div class='list'>
      <van-uploader :before-delete="beforeDel" multiple v-model="fileList"/>
    </div>

    <div class="btn" @click="submit">
      <span>提交</span>
    </div>
  </div>
</template>
<script>
import { toRefs, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import API from '@/server/api'
import { isEmptyObj } from '@/utils/index'
import { UploadFile } from '@/utils/upload'

export default {
  setup () {
    const route = useRoute()
    const Router = useRouter()
    const state = reactive({

      creditNo: '',
      userId: '',
      type: '',

      fileList: [],
      imgData: [], // 图片路径

      ossData: null
    })

    // 处理路由参数
    const init = () => {
      const query = route.query
      if (!isEmptyObj(query)) {
        state.routeInfo = query
        state.type = query.type
        state.creditNo = localStorage.getItem('creditNo')
        state.userId = localStorage.getItem('userId')
      }
    }

    // 获取图片列表
    const initImgList = async () => {
      try {
        const { creditNo, type } = state
        const res = await API.getPhotoList({ creditNo, type })
        if (res.success) {
          const cache = []
          res.echoList.forEach(item => {
            const o = {}
            o.url = item
            cache.push(o)
          })
          state.fileList = cache
        }
      } catch (err) {
        Toast(err.message)
        console.log(err.message)
      }
    }

    // 初始化函数
    onMounted(() => {
      init()
      initImgList()
    })

    // 保存提交
    const submit = async () => {
      if (!state.fileList.length) {
        Toast('请先上传附件！')
        return false
      }

      const cache = []
      for (const item of state.fileList) {
        const res = await new Promise((resolve, reject) => {
          if (item.url) {
            setTimeout(() => {
              resolve(item.url)
            }, 0)
          } else {
            setTimeout(async () => {
              await initOssConfig()
              const filePath = await UploadFile(state.ossData, item.content)
              resolve(filePath)
            }, 200)
          }
        })
        cache.push(res)
      }

      await Promise.all(cache).then(async res => {
        state.imgData = res
        await upload()
      })
    }

    // 获取oss验签
    const initOssConfig = async () => {
      try {
        const fileKey = 'BLMX-FILE'
        const res = await API.uploadOss({
          fileName: 'jpg',
          fileType: fileKey
        })
        if (res.success) {
          state.ossData = res.data
        }
      } catch (err) {
        Toast(err.message)
      }
    }

    const upload = async () => {
      if (!state.imgData.length) {
        Toast('请先上传附件！')
        return false
      }

      console.log(state.imgData, '上传的数据')

      try {
        const params = {
          creditNo: state.creditNo,
          userId: state.userId,
          imgModel: {
            imgData: state.imgData,
            type: state.type
          }
        }
        const res = await API.uploadFile({ ...params })
        if (res.success) {
          Toast.success('操作成功')
          setTimeout(() => {
            Router.back()
          }, 1000)
        }
      } catch (err) {
        Toast(err.message)
        console.log(err.message)
      }
    }

    // 删除前做些事情
    const beforeDel = (file) => {
      const { url } = file
      if (url && url.indexOf('http') !== '-1') {
        state.imgData = remove(state.imgData, url)
      }
      console.log(state.imgData)
      return true
    }

    const remove = (arr, item) => {
      for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i] === item) {
          arr.splice(i, 1)
        }
      }
      return arr
    }

    return {
      ...toRefs(state),
      submit,
      beforeDel
    }
  }
}
</script>
<style lang="less" src="./index.less" scoped></style>
