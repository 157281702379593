// 格式化金额，数字
const formatMoney = function (s, n) {
  console.log('formatMoney')
}
// 获取hash路由URL的参数
const GetUrlParams = function (href) {
  const u = href || window.location.href
  const o = {}
  const startNum = u.indexOf('?')
  if (startNum !== -1) {
    const paramsStr = u.substring(startNum + 1)
    if (paramsStr.indexOf('&') !== -1) {
      const cacheArr = paramsStr.split('&')
      cacheArr.forEach((item) => {
        const arr = item.split('=')
        o[arr[0]] = arr[1]
      })
    } else {
      const paramsArr = paramsStr.split('=')
      o[paramsArr[0]] = paramsArr[1]
    }
  }
  return o
}

//压缩方法
function dealImage(base64, w, fn, type) {
  console.log('压缩前：', base64.length / 1024);
  let p = new Promise((resolve, reject) => {
    var newImage = new Image();
    var quality = 0.6; //压缩系数0-1之间
    newImage.src = base64;
    newImage.setAttribute("crossOrigin", "Anonymous"); //url为外域时需要
    var imgWidth, imgHeight;
    newImage.onload = function () {
      imgWidth = this.width;
      imgHeight = this.height;
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      if (Math.max(imgWidth, imgHeight) > w) {
        if (imgWidth > imgHeight) {
          canvas.width = w;
          canvas.height = (w * imgHeight) / imgWidth;
        } else {
          canvas.height = w;
          canvas.width = (w * imgWidth) / imgHeight;
        }
      } else {
        canvas.width = imgWidth;
        canvas.height = imgHeight;
        quality = 0.6;
      }
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
      var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
      resolve(base64, type)
    }
  });
  console.log(p, 'pppppp');
  return p
  // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
  // while (base64.length / 1024 > 150) {
  // 	quality -= 0.01;
  // 	base64 = canvas.toDataURL("image/jpeg", quality);
  // }
  // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
  // while (base64.length / 1024 < 50) {
  // 	quality += 0.001;
  // 	base64 = canvas.toDataURL("image/jpeg", quality);
  // }
  // const imgArr=[];
  // imgArr.push(base64)

  //必须通过回调函数返回，否则无法及时拿到该值
}

// 判断对象是否为空
function isEmptyObj(e) {
  let t
  for (t in e) {
    return !1
  }
  return !0
}

// 转化日期
Date.prototype.format = function (fmt) {
  const o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}
const timeDate = Date.prototype.format
// 跳转的公共路径

const routerTag = ''

const cbak = function () {
  console.log('cbak')
}

const bindEvent = function (fn) {
  document.addEventListener('McEvent', cbak, true)
}

const EvalEvent = function () {
  document.dispatchEvent('McEvent')
}

const removeEvent = function (fn) {
  document.removeEventListener('McEvent', cbak, true)
}

// 身份证输入校验
const checkIDCard = function (idcode)  {
  // 加权因子
  // eslint-disable-next-line camelcase
  const weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  // 校验码
  // eslint-disable-next-line camelcase
  const check_code = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];

  const code = idcode + "";
  const last = idcode[17]; // 最后一位

  const seventeen = code.substring(0, 17);

  // ISO 7064:1983.MOD 11-2
  // 判断最后一位校验码是否正确
  const arr = seventeen.split("");
  const len = arr.length;
  let num = 0;
  for (let i = 0; i < len; i++) {
    num = num + arr[i] * weight_factor[i];
  }

  // 获取余数
  const resisue = num % 11;
  const last_no = check_code[resisue];

  const idcard_patter =
    /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X]|[x])$/;

  // 判断格式是否正确
  const format = idcard_patter.test(idcode);

  // console.log(format,last.toUpperCase(),last_no,88888888)

  const lastUpp = last.toUpperCase();

  // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
  return !!(lastUpp === last_no && format);
};

module.exports = {
  bindEvent,
  EvalEvent,
  removeEvent,
  formatMoney,
  GetUrlParams,
  isEmptyObj,
  timeDate,
  routerTag,
  dealImage,
  checkIDCard
}



